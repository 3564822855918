import React from "react";
import { ModalContext } from "./ModalContext";

export interface PageProps {
  children?: React.ReactNode;
}

export const Page = ({ children }: PageProps) => {
  return <ModalContext>{children}</ModalContext>;
};
