import { ACF_ImageTopSection } from "../acf";

export type ImageTopSectionProps = ACF_ImageTopSection;

export const ImageTopSection = ({ image }: ImageTopSectionProps) => {
  return (
    <div
      className="d-flex flex-column justify-content-center position-relative"
      style={{
        height: "55vh",
        minHeight: 400,
      }}
    >
      <img
        src={image?.url ?? window.wordpress?.postThumbnailUrl}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          opacity: 0.5,
          objectFit: "cover",
          zIndex: -1,
          filter: "brightness(0.75)",
        }}
      />
      <div className="container">
        <h1 className="text-white heading-shadow m-0">
          {window.wordpress?.post?.post_title}
        </h1>
      </div>
    </div>
  );
};
