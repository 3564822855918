import classNames from "classnames";
import { ACF_ImageSectionHeading } from "../../acf";

export interface ImageSectionHeadingProps extends ACF_ImageSectionHeading {
  className?: string;
}

export const ImageSectionHeading = ({ title, className }: ImageSectionHeadingProps) => {
  return (
    <h2 className={classNames(className, 'image-section-heading text-center')}>
      <span className="bg-primary text-white py-3 py-lg-4 px-3 px-lg-5 d-block d-sm-inline-block">
        {title}
      </span>
    </h2>
  );
};
