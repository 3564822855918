import React from "react";
import { formatHtml } from "./utils";

export interface WPFormProps {
  name: string;
}

export const WPForm = ({ name }: WPFormProps) => {
  React.useEffect(() => {
    window.wpforms?.init();
  }, []);

  return (
    <>
      {window.wordpress?.wpforms !== undefined &&
        formatHtml(window.wordpress?.wpforms[name])}
    </>
  );
};
