import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DrawerBackItem,
  DrawerItem,
  DrawerTriggerItem,
  Drawers,
} from "@simoneconti/react-drawers";
import * as bootstrap from "bootstrap";
import { t } from "i18next";
import React from "react";
import { Link } from "./Link";
import { WP_MenuItem } from "./interfaces";
import { getActiveLanguage } from "./utils";

export interface HeaderMobileProps {
  mainMenu: Array<WP_MenuItem>;
}

export const HeaderMobile = ({ mainMenu }: HeaderMobileProps) => {
  const height = 60;

  const offcanvasRef = React.useRef<HTMLDivElement>(null);

  const [offcanvas, setOffcanvas] = React.useState<bootstrap.Offcanvas>();

  React.useEffect(() => {
    const offcanvas = new bootstrap.Offcanvas("#offcanvas");
    setOffcanvas(offcanvas);
  }, []);

  return (
    <>
      <div
        id="header-mobile"
        className="d-lg-none"
        style={{ paddingBottom: height }}
      >
        <div
          style={{ height: height }}
          className="fixed-top bg-white border-bottom"
        >
          <div
            style={{ height: height }}
            className="container hstack justify-content-between"
          >
            <span style={{ flex: 1 }}>
              <button
                onClick={() => {
                  offcanvas?.toggle();
                }}
                type="button"
                className="btn btn-outline-primary"
                aria-controls="offcanvas"
              >
                <FontAwesomeIcon icon="bars" />
              </button>
            </span>
            <span style={{ flex: 1 }} className="d-flex justify-content-center">
              <a href={window.wordpress?.homeUrl}>
                <img
                  src={window.wordpress?.options?.logo?.url}
                  alt={window.wordpress?.options?.logo?.alt}
                  height={height - 10}
                ></img>
              </a>
            </span>
            <span style={{ flex: 1 }} className="invisible"></span>
          </div>
        </div>
        <Offcanvas innerRef={offcanvasRef} mainMenu={mainMenu} />
      </div>
    </>
  );
};

interface OffcanvasProps {
  innerRef: React.RefObject<HTMLDivElement>;
  mainMenu: Array<WP_MenuItem>;
}

const Offcanvas = ({ innerRef, mainMenu }: OffcanvasProps) => {
  // const searchInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      id="offcanvas"
      ref={innerRef}
      className="offcanvas offcanvas-start"
      tabIndex={-1}
      aria-labelledby="offcanvas"
    >
      <div className="offcanvas-header border-bottom">
        <h5 className="offcanvas-title">
          <a
            href={window.wordpress?.homeUrl}
            className="hstack gap-2 text-decoration-none"
          >
            <img
              src={window.wordpress?.options?.logo?.url}
              alt={window.wordpress?.options?.logo?.alt}
              height={50}
            ></img>
            <span>Ostia International School</span>
          </a>
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-0 position-relative">
        <Drawers drawerItemClassName="px-3 py-2 border-bottom">
          {mainMenu.map((item) => (
            <Link
              className="d-block px-3 py-3 border-bottom text-decoration-none"
              href={item.url}
            >
              <span>{item.title}</span>
            </Link>
          ))}
          <Link
            className="px-3 py-2 border-bottom text-decoration-none hstack gap-2 bg-primary text-white"
            href={
              "tel:" +
              window.wordpress?.options?.phone_number?.replaceAll(" ", "")
            }
          >
            <FontAwesomeIcon icon="phone-volume" />
            <span>{window.wordpress?.options?.phone_number}</span>
          </Link>
          {window.wordpress?.languages && (
            <DrawerTriggerItem
              newDrawer={
                <>
                  <BackItem />
                  {Object.values(window.wordpress.languages).map((language) => (
                    <a
                      className="px-3 py-2 border-bottom hstack gap-2 text-decoration-none"
                      href={language.url}
                    >
                      <img src={language.country_flag_url} height={20} />
                      {language.native_name}
                    </a>
                  ))}
                </>
              }
            >
              <DrawerItem
                justifyContent="space-between"
                start={{
                  center: (
                    <div className="hstack gap-2">
                      <img
                        src={getActiveLanguage()?.country_flag_url}
                        height={20}
                      />
                      <span>{getActiveLanguage()?.native_name}</span>
                    </div>
                  ),
                }}
                end={{ center: <FontAwesomeIcon icon="chevron-right" /> }}
              />
            </DrawerTriggerItem>
          )}
          {/* <DrawerTriggerItem
            onNewDrawerShown={() => {
              console.log("ok");
              searchInputRef.current?.focus();
            }}
            newDrawer={
              <>
                <BackItem />
                <form
                  className="d-flex p-3"
                  role="search"
                  action={window.wordpress?.homeUrl}
                >
                  <input
                    ref={searchInputRef}
                    name="s"
                    className="form-control me-2"
                    type="search"
                    placeholder={t("search")}
                    aria-label={t("search")}
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    {t("search")}
                  </button>
                </form>
              </>
            }
          >
            <DrawerItem
              className="gap-2"
              justifyContent="space-between"
              start={{
                center: (
                  <div className="hstack gap-2">
                    <FontAwesomeIcon icon="magnifying-glass" />
                    <span>{t("search")}</span>
                  </div>
                ),
              }}
              end={{ center: <FontAwesomeIcon icon="chevron-right" /> }}
            />
          </DrawerTriggerItem> */}
          {/* <form
            className="d-flex p-3"
            role="search"
            action={window.wordpress?.homeUrl}
          >
            <input
              name="s"
              className="form-control me-2"
              type="search"
              placeholder={t("search")}
              aria-label={t("search")}
            />
            <button className="btn btn-outline-primary" type="submit">
              {t("search")}
            </button>
          </form> */}
        </Drawers>
      </div>
    </div>
  );
};

const BackItem = () => (
  <DrawerBackItem>
    <DrawerItem
      className="gap-2"
      justifyContent="start"
      center={{
        center: <span className="text-lowercase">{t("back")}</span>,
      }}
      start={{
        center: <FontAwesomeIcon icon="chevron-left" />,
      }}
    />
  </DrawerBackItem>
);
