import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./locales/en";
import { it } from "./locales/it";
import { getActiveLanguage } from "./utils";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    it: { translation: it },
  },
  lng: getActiveLanguage()?.code,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
