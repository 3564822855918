import { useBreakpointChecker } from "./useBreakpointChecker";

export interface PageLayoutAProps {
  topSection?: React.ReactNode;
  children?: React.ReactNode;
}

export const PageLayoutA = ({ topSection, children }: PageLayoutAProps) => {

const desktop = useBreakpointChecker('lg');

  return (
    <>
      {topSection && <div id="top-section">{topSection}</div>}
      <div className="bg-light pt-1">
        <div className="container">
          <div style={{ position: "relative", marginTop: topSection ? -60 : desktop ? 130 : 10 }}>
            {/* <div>breadcrumb</div> */}
            <div className="bg-white shadow py-5 px-4 px-xxl-5">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
