import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Breakpoint, WP_MenuItem } from "./interfaces";

export const fixMenuItems = (
  items?: Array<WP_MenuItem> | false
): Array<WP_MenuItem> | undefined => {
  if (items === false) {
    return undefined;
  }
  const clonedItems = _.cloneDeep(items);
  const fixedMainMenu = clonedItems?.reduce<Array<WP_MenuItem>>((acc, curr) => {
    const parent = clonedItems.find(
      (item) => item.ID.toString() === curr.menu_item_parent
    );
    if (parent !== undefined) {
      if (parent.children === undefined) {
        parent.children = [];
      }
      parent.children.push(curr);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
  return fixedMainMenu ?? [];
};

export const formatHtml = (string?: string) => {
  return (
    <>
      {string ? (
        <div dangerouslySetInnerHTML={{ __html: string }}></div>
      ) : (
        <></>
      )}
    </>
  );
};

export const getCssVariable = (name: string): string => {
  return getComputedStyle(document.body).getPropertyValue(`--${name}`);
};

export const getBreakpointValue = (breakpoint: Breakpoint): number => {
  return parseInt(getCssVariable(`bs-breakpoint-${breakpoint}`));
};

export const getBreakpoints = (): Record<Breakpoint, number> => {
  return {
    lg: getBreakpointValue("lg"),
    md: getBreakpointValue("md"),
    sm: getBreakpointValue("sm"),
    xl: getBreakpointValue("xl"),
    xs: getBreakpointValue("xs"),
    xxl: getBreakpointValue("xxl"),
  };
};

export const getSortedBreakpoints = (): Array<[Breakpoint, number]> => {
  return Object.entries(getBreakpoints()).sort(([, a], [, b]) => {
    return a > b ? 1 : -1;
  }) as Array<[Breakpoint, number]>;
};

export const getCurrentBreakpoint = (): Breakpoint => {
  let breakpoint = "xs";
  getSortedBreakpoints().forEach(([name, width]) => {
    if (window.matchMedia(`(min-width: ${width}px)`).matches) {
      breakpoint = name;
    }
  });
  return breakpoint as Breakpoint;
};

export const checkBreakpoint = (
  breakpoint: Breakpoint,
  currentBreakpoint: Breakpoint
): boolean => {
  const breakpointList = getSortedBreakpoints().map((entry) => entry[0]);

  return (
    breakpointList.indexOf(breakpoint) <=
    breakpointList.indexOf(currentBreakpoint)
  );
};

export const stringify = (obj: object) => (
  <code>{JSON.stringify(obj, undefined, 2)}</code>
);

export const uuid = () => uuidv4();

export const getActiveLanguage = () =>
  window.wordpress?.languages
    ? Object.values(window.wordpress.languages).find(
        (language) => language.active
      )
    : undefined;
