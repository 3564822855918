import { ACF_TableSectionBody } from "../../acf";

export type TableSectionBodyProps = ACF_TableSectionBody;

export const TableSectionBody = ({ table, title }: TableSectionBodyProps) => {
  return (
    <div className="text-center">
      {title && <h2 className="text-black mb-4">{title}</h2>}
      <div className="table-responsive">
        <table
          className="table table-bordered border-dark table-striped align-middle mb-0"
          style={{ tableLayout: "fixed" }}
        >
          {table?.head?.columns && table?.head?.columns.length > 0 && (
            <thead>
              <tr className="table-primary">
                {table?.head?.columns.map(({ cell }) => (
                  <th className="text-uppercase h5">{cell}</th>
                ))}
              </tr>
            </thead>
          )}
          {table?.rows && table?.rows.length > 0 && (
            <tbody className="lh-sm">
              {table.rows.map(
                ({ columns }) =>
                  columns &&
                  columns.length > 0 && (
                    <tr>
                      {columns.map(({ cell }) => (
                        <td>{cell}</td>
                      ))}
                    </tr>
                  )
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
