import { PageLayoutA } from "../PageLayoutA";
import { ACF_PageBuilder } from "../acf";
import { ACF_FlexibleContentComponent } from "../acf_module/ACF_FlexibleContentComponent";
import { AlternateBlocksSectionBody } from "../sections/body/AlternateBlocksSectionBody";
import { CirclesSectionBody } from "../sections/body/CirclesSectionBody";
import { ContactUsSectionBody } from "../sections/body/ContactUsSectionBody";
import { SimpleContentSectionBody } from "../sections/body/SimpleContentSectionBody";
import { SimpleImageSectionBody } from "../sections/body/SimpleImageSectionBody";
import { TableSectionBody } from "../sections/body/TableSectionBody";
import { VideoAndImagesSectionBody } from "../sections/body/VideoAndImagesSectionBody";
import { DoubleTitleSectionHeading } from "../sections/heading/DoubleTitleSectionHeading";
import { ImageSectionHeading } from "../sections/heading/ImageSectionHeading";
import { ImageTopSection } from "../topSections/ImageTopSection";
import { VideoTopSection } from "../topSections/VideoTopSection";

export interface PageBuilderProps {
  acf?: ACF_PageBuilder;
}

export const PageBuilder = ({ acf }: PageBuilderProps) => {
  console.log("top section", acf?.top_section);
  return (
    <PageLayoutA
      topSection={
        acf?.top_section ? (
          <ACF_FlexibleContentComponent
            layouts={acf?.top_section}
            mapping={{
              image_top_section: (props) => <ImageTopSection {...props} />,
              video_top_section: (props) => <VideoTopSection {...props} />,
            }}
          />
        ) : undefined
      }
    >
      <div id="sections" className="my-n5 overflow-hidden">
        {acf?.sections &&
          acf?.sections?.map((section) => (
            <div className="section my-5">
              <div className="section-heading">
                <ACF_FlexibleContentComponent
                  layouts={section.heading}
                  mapping={{
                    double_title_section_heading: (props) => (
                      <DoubleTitleSectionHeading {...props} />
                    ),
                    image_section_heading: (props) => (
                      <ImageSectionHeading {...props} />
                    ),
                  }}
                />
              </div>
              <div className="section-body">
                <ACF_FlexibleContentComponent
                  className="my-5"
                  layouts={section.body}
                  mapping={{
                    alternate_blocks_section_body: (props) => (
                      <AlternateBlocksSectionBody {...props} />
                    ),
                    circles_section_body: (props) => (
                      <CirclesSectionBody {...props} />
                    ),
                    simple_content_section_body: (props) => (
                      <SimpleContentSectionBody {...props} />
                    ),
                    simple_image_section_body: (props) => (
                      <SimpleImageSectionBody {...props} />
                    ),
                    table_section_body: (props) => (
                      <TableSectionBody {...props} />
                    ),
                    video_and_images_section_body: (props) => (
                      <VideoAndImagesSectionBody {...props} />
                    ),
                    contact_us_section_body: (props) => (
                      <ContactUsSectionBody {...props} />
                    ),
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </PageLayoutA>
  );
};
