import classNames from "classnames";
import { Breakpoint } from "./interfaces";
import { useBreakpoint } from "./useBreakpoint";
import { useImageZoom } from "./useImageZoom";

export interface PhotoFrameProps {
  image: string;
  secondFramePlacement?: "start" | "end";
  hideSecondFrame?: Array<Breakpoint>;
  className?: string;
}

export const PhotoFrame = ({
  image,
  secondFramePlacement = "end",
  hideSecondFrame = [],
  className,
}: PhotoFrameProps) => {
  const size = 370;
  const border = 40;
  const offset = 60;

  const breakpoint = useBreakpoint();

  const { zoom } = useImageZoom();

  return (
    <div
      className={classNames(className, "d-inline-block zoom")}
      onClick={() => {
        zoom(image);
      }}
    >
      <img
        className="d-block shadow-lg"
        style={{
          zIndex: 2,
          maxWidth: "100%",
          width: size,
          height: size,
          borderWidth: border,
          objectFit: "cover",
          position: "relative",
          borderColor: "#eff7fb",
          marginLeft:
            !hideSecondFrame.includes(breakpoint) &&
            secondFramePlacement === "start"
              ? offset
              : undefined,
        }}
        src={image}
      />
      <div
        className={classNames("border-primary", {
          "d-none": hideSecondFrame.includes(breakpoint),
        })}
        style={{
          zIndex: 1,
          width: size,
          height: size,
          borderWidth: border,
          position: "relative",
          marginTop: -(size - offset * 1.25),
          marginLeft: secondFramePlacement === "end" ? offset : undefined,
        }}
      ></div>
    </div>
  );
};
