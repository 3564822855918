import classNames from "classnames";

export interface ModalProps {
  title?: string;
  body?: React.ReactElement;
  backdrop?: boolean | "static";
  scrollable?: boolean;
  centered?: boolean;
  fade?: boolean;
  size?: "small" | "default" | "large" | "extra-large";
  className?: string;
}

export const Modal = ({
  className,
  title,
  body,
  innerRef,
  backdrop = true,
  scrollable,
  centered,
  fade = true,
  size = "default",
}: ModalProps & {
  innerRef?: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <div
      className={classNames("modal", className, { fade: fade })}
      data-bs-backdrop={backdrop}
      tabIndex={-1}
      ref={innerRef}
    >
      <div
        className={classNames("modal-dialog", {
          "modal-dialog-scrollable": scrollable,
          "modal-dialog-centeredf": centered,
          "modal-sm": size === "small",
          "modal-lg": size === "large",
          "modal-xl": size === "extra-large",
        })}
      >
        <div className="modal-content">
          <div className="modal-header">
            {title && <h5 className="modal-title">{title}</h5>}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{body}</div>
        </div>
      </div>
    </div>
  );
};
