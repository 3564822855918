import classNames from "classnames";
import React from "react";

export type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const Link = ({
  href,
  children,
  className,
  ...otherProps
}: LinkProps) => {
  const active = React.useMemo<boolean>(() => {
    return href === window.location.href;
  }, [href]);
  return (
    <a
      className={classNames(className, { active: active })}
      href={href}
      {...otherProps}
    >
      {children}
    </a>
  );
};
