import { ResponsoveYouTubeVideo } from "../../ResponsoveYouTubeVideo";
import { ACF_VideoAndImagesSectionBody } from "../../acf";
import { useImageZoom } from "../../useImageZoom";

export type VideoAndImagesSectionBodyProps = ACF_VideoAndImagesSectionBody;

export const VideoAndImagesSectionBody = ({
  images,
  video,
}: VideoAndImagesSectionBodyProps) => {
  const { zoom } = useImageZoom();

  return (
    <div className="row align-items-center gx-lg-4">
      {
        <div className="col-12 col-lg-7">
          {video && (
            <ResponsoveYouTubeVideo
              id={video}
              className="mb-4 mb-lg-0 shadow-a"
            />
          )}
        </div>
      }
      <div className="col-12 col-lg-5">
        <div className="row mb-n3">
          {images &&
            images?.map(
              ({ image }) =>
                image && (
                  <div className="col-6">
                    <img
                      src={image.url}
                      className="img-fluid rounded-circle mb-3 shadow img-thumbnail zoom"
                      onClick={() => {
                        zoom(image.url);
                      }}
                    />
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};
