import { Modal as BS_Modal } from "bootstrap";
import React from "react";
import { createPortal } from "react-dom";
import { Modal, ModalProps } from "./Modal";

export interface ModalContext {
  showModal(props: Omit<ModalProps, "id">): void;
  hideModal(): void;
}

const modalContext = React.createContext<ModalContext>({
  showModal: () => undefined,
  hideModal: () => undefined,
});

export interface ModalContextProps {
  children?: React.ReactNode;
}

export const ModalContext = ({ children }: ModalContextProps) => {
  const [modalProps, setModalProps] = React.useState<ModalProps>();

  const modalRef = React.useRef<HTMLDivElement>(null);
  // const modal = React.useRef<BS_Modal>();

  // React.useEffect(() => {
  //   const modalElement = modalRef.current;
  //   if (modalElement) {
  //     modal.current = new BS_Modal(modalElement);

  //     modalRef.current.addEventListener("hide.bs.modal", () => {
  //       // console.log("hide.bs.modal");
  //     });

  //     modalRef.current.addEventListener("hidden.bs.modal", () => {
  //       // console.log("hidden.bs.modal");
  //     });

  //     modalRef.current.addEventListener("hidePrevented.bs.modal", () => {
  //       // console.log("hidePrevented.bs.modal");
  //     });

  //     modalRef.current.addEventListener("show.bs.modal", () => {
  //       // console.log("show.bs.modal");
  //     });

  //     modalRef.current.addEventListener("shown.bs.modal", () => {
  //       // console.log("shown.bs.modal");
  //     });
  //   }
  //   return () => {
  //     modal.current?.dispose();
  //   };
  // }, []);

  const getModal = React.useCallback(() => {
    if (modalRef.current) {
      return BS_Modal.getOrCreateInstance(modalRef.current);
    }
  }, []);

  const hideModal = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      const hiddenListener = () => {
        console.log("hidden.bs.modal", "custom");
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          hiddenListener
        );
        resolve();
      };
      modalRef.current?.addEventListener("hidden.bs.modal", hiddenListener);
      getModal()?.hide();
    });
  }, [getModal]);

  const showModal = React.useCallback(
    (props: Omit<ModalProps, "id">) => {
      return new Promise<void>((resolve) => {
        const show = () => {
          const shownListener = () => {
            console.log("shown.bs.modal");
            modalRef.current?.removeEventListener(
              "shown.bs.modal",
              shownListener
            );
            resolve();
          };
          modalRef.current?.addEventListener("shown.bs.modal", shownListener);

          const hiddenListener = () => {
            console.log("hidden.bs.modal", "native");
            modalRef.current?.removeEventListener(
              "hidden.bs.modal",
              hiddenListener
            );
            getModal()?.dispose();
            setModalProps(undefined);
          };
          modalRef.current?.addEventListener("hidden.bs.modal", hiddenListener);
          setModalProps(props);
          setTimeout(() => {
            getModal()?.show();
          }, 0);
        };

        const visible = modalRef.current?.classList.contains("show");

        if (visible) {
          hideModal().then(() => {
            show();
          });
        } else {
          show();
        }
      });
    },
    [getModal, hideModal]
  );

  return (
    <>
      {createPortal(
        <Modal innerRef={modalRef} {...modalProps} />,
        document.body
      )}
      <modalContext.Provider value={{ showModal, hideModal }}>
        {children}
      </modalContext.Provider>
    </>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useModalContext = () => {
  return React.useContext(modalContext);
};
