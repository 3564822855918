import classnames from "classnames";

export interface SectionHeadingBProps {
  title?: string;
  className?: string;
}

export const SectionHeadingB = ({ title, className }: SectionHeadingBProps) => {
  return (
    <h2 className={classnames(className, "h1 text-primary text-center mt-5 mb-4")}>
      {title}
    </h2>
  );
};
