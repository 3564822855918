import React from "react";
import { useWindowSize } from "react-use";

export interface YouTubeVideoProps {
  id: string;
  limitToWindowHeightFrom?: number;
  hideOverlaysFrom?: number;
}

export const YouTubeVideo = ({
  id,
  limitToWindowHeightFrom,
  hideOverlaysFrom,
}: YouTubeVideoProps) => {
  const videoWrapperRef = React.useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();

  const [videoWrapperHeight, setVideoWrapperHeight] = React.useState<number>();

  const [videoIframeWidth, setVideoIframeWidth] = React.useState<number>();
  const [videoIframeHeight, setVideoIframeHeight] = React.useState<number>();

  React.useEffect(() => {
    const videowidth = 1920;
    const videoHeight = 1080;
    const videoRatio = videowidth / videoHeight;

    const videoHeightOffsetToRemoveLogos =
      hideOverlaysFrom !== undefined && windowSize.width >= hideOverlaysFrom
        ? videowidth / 10
        : 0;
    const videoFixedRatio =
      videowidth / (videoHeight - videoHeightOffsetToRemoveLogos);

    const videoWrapperWidth = videoWrapperRef.current?.clientWidth;
    if (videoWrapperWidth) {
      setVideoWrapperHeight(
        limitToWindowHeightFrom != undefined &&
          windowSize.width >= limitToWindowHeightFrom &&
          videoWrapperWidth / videoFixedRatio > windowSize.height
          ? windowSize.height
          : videoWrapperWidth / videoFixedRatio
      );
      setVideoIframeWidth(videoWrapperWidth);
      setVideoIframeHeight(videoWrapperWidth / videoRatio);
    }
  }, [
    hideOverlaysFrom,
    limitToWindowHeightFrom,
    windowSize.height,
    windowSize.width,
  ]);
  return (
    <div
      ref={videoWrapperRef}
      style={{
        width: "100%",
        height: videoWrapperHeight + "px",
        overflow: "hidden",
        position: "relative",
        margin: "auto",
      }}
    >
      <iframe
        style={{
          width: videoIframeWidth,
          height: videoIframeHeight,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
        src={`https://www.youtube.com/embed/${id}?autoplay=1&mute=1&controls=0&showinfo=0&modestbranding=1&loop=1&playlist=${id}`}
      />
    </div>
  );
};
