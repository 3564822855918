import classNames from "classnames";
import { ACF_DoubleTitleSectionHeading } from "../../acf";

export interface DoubleTitleSectionHeadingProps
  extends ACF_DoubleTitleSectionHeading {
  className?: string;
}

export const DoubleTitleSectionHeading = ({
  subtitle,
  title,
  className,
}: DoubleTitleSectionHeadingProps) => {
  return (
    <hgroup
      className={classNames(
        className,
        "double-title-section-heading text-center bg-primary p-3"
      )}
    >
      {title && <h1 className="title text-uppercase m-0">{title}</h1>}
      {subtitle && (
        <h2 className="subtitle text-lowercase text-white m-0">{subtitle}</h2>
      )}
    </hgroup>
  );
};
