import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ReactComponent as ItalianFlag } from "flag-icons/flags/4x3/it.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "./Link";

import * as bootstrap from "bootstrap";
import classNames from "classnames";
import { useClickAway, useKey, useWindowScroll } from "react-use";
import { WP_MenuItem } from "./interfaces";
import { getActiveLanguage } from "./utils";

export interface HeaderDesktopProps {
  mainMenu: Array<WP_MenuItem>;
}

export const HeaderDesktop = ({ mainMenu }: HeaderDesktopProps) => {
  const { y } = useWindowScroll();

  const [isSearchVisible, setIsSearchVisible] = React.useState<boolean>(false);

  const checkCompact = React.useCallback(() => y > 0, [y]);

  const checkTransparent = React.useCallback(
    () => y === 0 && !isSearchVisible && window.acf?.transparent_header === true,
    [isSearchVisible, y]
  );

  const [compact, setCompact] = React.useState<boolean>(checkCompact());
  const [transparent, setTransparent] = React.useState<boolean>(
    checkTransparent()
  );

  React.useEffect(() => {
    setCompact(checkCompact());
  }, [checkCompact]);

  React.useEffect(() => {
    setTransparent(checkTransparent());
  }, [checkTransparent]);

  React.useEffect(() => {
    if (navbarToggleExternalContentRef.current) {
      navbarToggleExternalContentRef.current.addEventListener(
        "show.bs.collapse",
        () => {
          setIsSearchVisible(true);
        }
      );
      navbarToggleExternalContentRef.current.addEventListener(
        "hide.bs.collapse",
        () => {
          setIsSearchVisible(false);
        }
      );
      navbarToggleExternalContentRef.current.addEventListener(
        "shown.bs.collapse",
        () => {
          searchInputRef.current?.focus();
        }
      );
    }
  }, []);

  const transitionSpeed = 300;

  const { t } = useTranslation();

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const navbarToggleExternalContentRef = React.useRef<HTMLDivElement>(null);

  const [navbarToggleExternalContent, setNavbarToggleExternalContent] =
    React.useState<bootstrap.Collapse>();

  React.useEffect(() => {
    const navbarToggleExternalContent = new bootstrap.Collapse(
      "#navbarToggleExternalContent",
      {
        toggle: false,
      }
    );
    setNavbarToggleExternalContent(navbarToggleExternalContent);
  }, []);

  const hideNavbarToggleExternalContent = React.useCallback(() => {
    if (searchInputRef.current?.value === "") {
      navbarToggleExternalContent?.hide();
    }
  }, [navbarToggleExternalContent]);

  useKey("Escape", hideNavbarToggleExternalContent, undefined, [
    navbarToggleExternalContent,
  ]);

  const ref = React.useRef<HTMLDivElement>(null);

  useClickAway(ref, hideNavbarToggleExternalContent);

  return (
    <div ref={ref} id="header-desktop" className="fixed-top d-none d-lg-block">
      <nav
        className={classNames("navbar navbar-expand", {
          "bg-dark bg-opacity-50": transparent,
          "bg-white border-bottom": !transparent,
        })}
        style={{ transition: "background-color " + transitionSpeed + "ms" }}
      >
        <div className="container">
          <a className="navbar-brand" href={window.wordpress?.homeUrl}>
            <img
              src={window.wordpress?.options?.logo?.url}
              alt={window.wordpress?.options?.logo?.alt}
              height={compact ? 45 : 100}
              style={{ transition: "height " + transitionSpeed + "ms" }}
            ></img>
          </a>
          <ul className="navbar-nav ms-auto">
            {mainMenu.map((item) => (
              <li className="nav-item">
                <Link
                  className={classNames("nav-link text-uppercase fw-medium", {
                    "text-white": transparent,
                  })}
                  aria-current="page"
                  href={item.url}
                >
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
          <a
            className="btn btn btn-primary mx-2 hstack gap-2"
            href={
              "tel:" +
              window.wordpress?.options?.phone_number?.replaceAll(" ", "")
            }
            style={{ alignSelf: "auto" }}
          >
            <FontAwesomeIcon icon="phone-volume" />
            <span>{window.wordpress?.options?.phone_number}</span>
          </a>
          {/* <ItalianFlag height={20} /> */}
          {window.wordpress?.languages && (
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className={classNames(
                    "nav-link dropdown-toggle d-flex align-items-center",
                    { "text-white": transparent }
                  )}
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={getActiveLanguage()?.country_flag_url}
                    height={20}
                  />
                </a>
                <ul className="dropdown-menu">
                  {Object.values(window.wordpress.languages).map((language) => (
                    <li key={language.code}>
                      <a
                        className="dropdown-item hstack gap-2"
                        href={language.url}
                      >
                        <img src={language.country_flag_url} height={20} />
                        {language.native_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          )}
          {/* <button
            onClick={() => {
              navbarToggleExternalContent?.toggle();
            }}
            className="btn btn-link"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="___#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle search"
          >
            <FontAwesomeIcon
              icon="magnifying-glass"
              className={classNames({ "text-white": transparent })}
              style={{ transition: "color " + transitionSpeed + "ms" }}
            />
          </button> */}
        </div>
      </nav>
      <div
        className="collapse"
        id="navbarToggleExternalContent"
        ref={navbarToggleExternalContentRef}
      >
        <div
          className={classNames("py-3", {
            "bg-dark bg-opacity-25": transparent,
            "bg-white": !transparent,
          })}
          style={{ transition: "background-color " + transitionSpeed + "ms" }}
        >
          <div className="container">
            <form
              className="d-flex"
              role="search"
              action={window.wordpress?.homeUrl}
            >
              <input
                ref={searchInputRef}
                name="s"
                className="form-control me-2"
                type="search"
                placeholder={t("search")}
                aria-label={t("search")}
              />
              <button className="btn btn-outline-primary" type="submit">
                {t("search")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
