import { ACF_SimpleContentSectionBody } from "../../acf";
import { formatHtml } from "../../utils";

export type SimpleContentSectionBodyProps = ACF_SimpleContentSectionBody;

export const SimpleContentSectionBody = ({
  content,
  subtitle,
  title,
}: SimpleContentSectionBodyProps) => {
  return (
    <div className="text-center">
      {title && <h1 className="text-primary mb-4">{title}</h1>}
      {subtitle && <h5 className="text-body-tertiary mb-4">{subtitle}</h5>}
      {content && <div>{formatHtml(content)}</div>}
    </div>
  );
};
