import { ACF_CirclesSectionBody } from "../../acf";
import { formatHtml } from "../../utils";

export type CirclesSectionBodyProps = ACF_CirclesSectionBody;

export const CirclesSectionBody = ({
  blocks,
  title,
}: CirclesSectionBodyProps) => {
  return (
    <>
      {title && (
        <h3 className="text-uppercase text-body-secondary text-center mb-5">
          {title}
        </h3>
      )}
      {blocks && blocks.length > 0 && (
        <div className="row mb-n5">
          {blocks?.map(
            ({
              content,
              image,
              title,
              blur_image,
              subtitle,
              size = "col-12 col-md-6",
            }) => (
              <div className={size}>
                {" "}
                {/* COL */}
                <div className="mb-4">
                  <div
                    className="overflow-hidden rounded-circle mb-3 mx-auto"
                    style={{ maxWidth: 250 }}
                  >
                    <img
                      src={image?.url}
                      className="img-fluid"
                      style={{ filter: blur_image ? "blur(20px)" : undefined }}
                    />
                  </div>
                  {title && (
                    <h6 className="mb-3 text-center">
                      <strong>{title}</strong>
                      {subtitle && <> - {subtitle}</>}
                    </h6>
                  )}
                  {content && (
                    <div className="bg-light p-3 pb-0 small overflow-hidden">
                      {formatHtml(content)}
                    </div>
                  )}
                  {blur_image}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};
