import { ACF_VideoTopSection } from "../acf";

export type VideoTopSectionProps = ACF_VideoTopSection;

export const VideoTopSection = ({ video }: VideoTopSectionProps) => {
  return (
    <div>
      VideoTopSection
      <br />
      {video}
    </div>
  );
};
