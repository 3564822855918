import { WPForm } from "../../WPForm";
import { ACF_ContactUsSectionBody } from "../../acf";
import { formatHtml } from "../../utils";
// import { formatHtml } from "../../utils";

export type ContactUsSectionBodyProps = ACF_ContactUsSectionBody;

export const ContactUsSectionBody = ({
  address,
  email,
  form_disclaimer,
  form_title,
  map_title,
}: ContactUsSectionBodyProps) => {
  return (
    <>
      <div className="row gx-lg-4">
        <div className="col-12 col-lg-6">
          <h3>Ostia International School</h3>
          <div className="lead">
            <div className="mb-4">{formatHtml(address)}</div>
            <div>
              <strong>Tel: </strong>
              <a
                href={
                  "tel:" +
                  window.wordpress?.options?.phone_number?.replaceAll(" ", "")
                }
              >
                {window.wordpress?.options?.phone_number}
              </a>
            </div>
            <div>
              <strong>Email: </strong>
              <a href={"mailto:" + email}>{email}</a>
            </div>
          </div>
          <hr className="my-4" />
          <h3>{map_title}</h3>
          <iframe
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.1064999808887!2d12.310939676693156!3d41.71822067540805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1325ee3a5f9ffd67%3A0xa790ec3fe76e4d6e!2sOstia%20International%20School%20Srl!5e0!3m2!1sit!2sus!4v1696311786870!5m2!1sit!2sus"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDoXROUgTXZpS-LNbRyBb7P5MK1EwzOxaI&q=Via Andrea Corsali,10  Ostia Lido 00122 Roma &zoom=13"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <hr className="my-4 d-lg-none" />
        </div>
        <div className="col-12 col-lg-6">
          <h3>{form_title}</h3>
          <WPForm name="contactUs" />
          <div className="mt-4">
            <small>{formatHtml(form_disclaimer)}</small>
          </div>
        </div>
      </div>
    </>
  );
};
