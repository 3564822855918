export interface ResponsoveYouTubeVideoProps {
  id: string;
  className?: string;
}

export const ResponsoveYouTubeVideo = ({
  id,
  className,
}: ResponsoveYouTubeVideoProps) => {
  return (
    <div
      className={className}
      style={{
        width: "100%",
        height: 0,
        paddingBottom: "56.25%",
        position: "relative",
      }}
    >
      <iframe
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        src={`https://www.youtube.com/embed/${id}?controls=0&showinfo=0&modestbranding=1&loop=1&playlist=${id}`}
      />
    </div>
  );
};
