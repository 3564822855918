import { PageLayoutA } from "../PageLayoutA";
import { ImageTopSection } from "../topSections/ImageTopSection";
import { formatHtml } from "../utils";

export const DefaultPage = () => {
  return (
    <PageLayoutA topSection={<ImageTopSection />}>
      <div className="px-5">
        {formatHtml(window.wordpress?.post?.post_content)}
      </div>
    </PageLayoutA>
  );
};
