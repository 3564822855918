import classNames from "classnames";
import { PhotoFrame } from "../../PhotoFrame";
import { ACF_AlternateBlocksSectionBody } from "../../acf";
import { formatHtml } from "../../utils";

export type AlternateBlocksSectionBodyProps = ACF_AlternateBlocksSectionBody;

export const AlternateBlocksSectionBody = ({
  blocks,
  show_numbers
}: AlternateBlocksSectionBodyProps) => {
  return (
    <>
      {blocks &&
        blocks?.map(({ content, image, title }, index) => (
          <div
            className={classNames("row gx-lg-5 align-items-center", {
              "mt-5": index !== 0,
            })}
          >
            <div
              className={classNames("col-12 col-lg-7", {
                "order-lg-last": index % 2 === 1,
              })}
            >
              <div className="hstack gap-3 mb-4">
                {show_numbers && <div className="h6 fw-bold">{index + 1}</div>}
                <div className="text-body-tertiary" style={{ width: 150 }}>
                  <hr className="opacity-100" />
                </div>
              </div>
              <h3 className="text-body-secondary text-uppercase mb-4">
                {title}
              </h3>
              <div className="mb-4 mb-lg-0">{formatHtml(content)}</div>
            </div>
            <div className="col-12 col-lg-5 text-center">
              {image?.url && (
                <PhotoFrame
                  image={image?.url}
                  secondFramePlacement={index % 2 === 1 ? "start" : "end"}
                  hideSecondFrame={["xs", "sm", "lg", "xl"]}
                />
              )}
            </div>
          </div>
        ))}
    </>
  );
};
