import React from "react";
import { HeaderDesktop } from "./HeaderDesktop";
import { HeaderMobile } from "./HeaderMobile";
import { fixMenuItems } from "./utils";

export const Header = () => {
  const mainMenu = React.useMemo(
    () => fixMenuItems(window.wordpress?.mainMenu),
    []
  );

  return (
    <>
      {mainMenu && (
        <>
          <HeaderDesktop mainMenu={mainMenu} />
          <HeaderMobile mainMenu={mainMenu} />
        </>
      )}
    </>
  );
};
