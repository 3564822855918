import { ACF_SimpleImageSectionBody } from "../../acf";

export type SimpleImageSectionBodyProps = ACF_SimpleImageSectionBody;

export const SimpleImageSectionBody = ({
  image,
  subtitle,
  title,
}: SimpleImageSectionBodyProps) => {
  return (
    <div className="text-center">
      {title && <h1 className="text-primary mb-4">{title}</h1>}
      {subtitle && <h5 className="text-body-tertiary mb-4">{subtitle}</h5>}
      {image && <img className="img-fluid" src={image?.url} />}
    </div>
  );
};
