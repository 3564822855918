import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { fixMenuItems, formatHtml } from "./utils";

export const Footer = () => {
  const footerMenu = React.useMemo(
    () => fixMenuItems(window.wordpress?.footerMenu),
    []
  );
  return (
    <>
      {window.acf?.show_footer && (
        <>
          <div id="footer-main-section" className="text-white text-center pt-5">
            <div className="container-fluid">
              <h1 className="mb-4">
                {window.wordpress?.options?.footer?.main_section?.title}
              </h1>
            </div>
            <div className="container">
              <div className="col-12 col-md-8 m-auto">
                {formatHtml(
                  window.wordpress?.options?.footer?.main_section?.content
                )}
              </div>
              <div></div>
            </div>
          </div>
          <div
            id="footer-info-section"
            className="bg-white text-primary text-center py-4 px-3 mx-2 mx-lg-4 rounded-4 shadow"
          >
            <h1 className="mb-4">
              {window.wordpress?.options?.footer?.info_section?.title}
            </h1>
            <div className="mb-4">
              <div className="col-12 col-md-8 m-auto">
                {formatHtml(
                  window.wordpress?.options?.footer?.info_section?.content
                )}
              </div>
            </div>
            <a
              className="btn btn-primary rounded-pill btn-lg px-5"
              href={window.wordpress?.options?.footer?.info_section?.cta?.page}
            >
              {window.wordpress?.options?.footer?.info_section?.cta?.label}
            </a>
          </div>
          <div
            id="footer-social-section"
            className="bg-white text-primary text-center py-5"
          >
            <div className="container">
              <h1 className="mb-4">
                {window.wordpress?.options?.footer?.social_section?.title}
              </h1>
              <div className="d-flex justify-content-center gap-4">
                {window.wordpress?.options?.footer?.social_section?.socials !==
                  false &&
                  window.wordpress?.options?.footer?.social_section?.socials?.map(
                    ({ link, icon }) => (
                      <a href={link} className="text-reset">
                        <FontAwesomeIcon
                          className="text-reset"
                          icon={{
                            iconName: ("square-" + icon) as IconName,
                            prefix: "fab",
                          }}
                          size="4x"
                        />
                      </a>
                    )
                  )}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="bg-dark text-light py-4">
        <div className="container">
          <div className="d-flex flex-column flex-lg-row justify-content-between gap-2">
            <div>
              {formatHtml(window.wordpress?.options?.footer?.copyright)}
            </div>
            <nav className="hstack gap-2 align-self-center">
              {footerMenu?.map((item, index) => (
                <>
                  {index !== 0 && <div className="vr"></div>}
                  <a
                    className="text-decoration-none text-reset"
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
