import { ACF_FlexibleContentField } from "./acf";

export interface ACF_FlexibleContentComponentProps<
  LayoutsNames extends string,
  LayoutFields extends object
> {
  mapping: Record<
    LayoutsNames,
    (otherProps: Omit<LayoutFields, "acf_fc_layout">) => React.ReactElement
  >;
  layouts?: ACF_FlexibleContentField<LayoutsNames, LayoutFields>;
  className?: string;
  style?: React.CSSProperties;
}

export const ACF_FlexibleContentComponent = <
  LayoutsNames extends string,
  LayoutFields extends object
>({
  layouts,
  mapping,
  className,
  style,
}: ACF_FlexibleContentComponentProps<LayoutsNames, LayoutFields>) => {
  return (
    <>
      {layouts &&
        layouts?.map(({ acf_fc_layout, ...otherProps }) => (
          <div
            data-acf-fc-layout={acf_fc_layout}
            className={className}
            style={style}
          >
            {mapping[acf_fc_layout](otherProps)}
          </div>
        ))}
    </>
  );
};
