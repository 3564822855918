import React from "react";
import { Breakpoint } from "./interfaces";
import { useBreakpoint } from "./useBreakpoint";
import { checkBreakpoint } from "./utils";

export const useBreakpointChecker = (breakpoint: Breakpoint): boolean => {
  const currentBreakpoint = useBreakpoint();
  const check = React.useMemo(
    () => checkBreakpoint(breakpoint, currentBreakpoint),
    [breakpoint, currentBreakpoint]
  );

  return check;
};
