import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as classNames, default as classnames } from "classnames";
import { t } from "i18next";
import { ResponsoveYouTubeVideo } from "../ResponsoveYouTubeVideo";
import { SectionHeadingB } from "../SectionHeadingB";
import { YouTubeVideo } from "../YouTubeVideo";
import { ACF_HomePage } from "../acf";
import { DoubleTitleSectionHeading } from "../sections/heading/DoubleTitleSectionHeading";
import { ImageSectionHeading } from "../sections/heading/ImageSectionHeading";
import { useImageZoom } from "../useImageZoom";
import { formatHtml } from "../utils";
import { useBreakpoint } from "../useBreakpoint";

export interface HomePageProps {
  acf?: ACF_HomePage;
}

export const HomePage = ({ acf }: HomePageProps) => {
  const { zoom } = useImageZoom();
  const breakpoint = useBreakpoint();

  return (
    <>
      <div id="top-video">
        {acf?.top_video?.youtube_video_id && (
          <YouTubeVideo
            id={acf?.top_video?.youtube_video_id}
            limitToWindowHeightFrom={992}
            hideOverlaysFrom={992}
          />
        )}
      </div>
      <div id="childrens-future" className="row g-0">
        <div className="col-lg-6 bg-primary">
          <div className="text-white p-4 d-flex flex-column h-100 justify-content-center">
            {acf?.childrens_future?.title && (
              <h2 className="text-center mb-4">
                {acf?.childrens_future?.title}
              </h2>
            )}
            <div className="mb-4">
              {formatHtml(acf?.childrens_future?.body)}
            </div>
            {acf?.childrens_future?.cta && (
              <div className="text-center">
                <a
                  className="btn btn-light rounded-pill btn-lg px-5"
                  href={acf?.childrens_future?.cta?.page}
                >
                  {acf?.childrens_future?.cta?.label}
                </a>
              </div>
            )}
          </div>
        </div>
        {acf?.childrens_future?.youtube_video_id && (
          <div className="col-lg-6 bg-black">
            <div className="d-flex flex-column h-100 justify-content-center">
              <ResponsoveYouTubeVideo
                id={acf?.childrens_future?.youtube_video_id}
              />
            </div>
          </div>
        )}
      </div>
      <div id="bilingual-programme" className="container">
        <ImageSectionHeading title={acf?.bilingual_programme?.title} className="my-4" />
        <div className="text-center">
          {formatHtml(acf?.bilingual_programme?.body)}
        </div>
        <div className="text-center my-4">
          <a
            className="btn btn-lg btn-outline-primary px-4"
            href={acf?.bilingual_programme?.cta?.page}
          >
            {acf?.bilingual_programme?.cta?.label}
          </a>
        </div>
      </div>
      <div id="academics">
        <DoubleTitleSectionHeading
          subtitle={acf?.academics?.subtitle}
          title={acf?.academics?.title}
          className="my-4"
        />
        {acf?.academics?.levels &&
          acf?.academics?.levels?.map((level, index) => (
            <div className="row g-0 mb-1 mb-md-0" key={index + 1}>
              <div
                className={classnames(
                  "col-md-6 bg-primary d-flex justify-content-center",
                  index % 2 === 0
                    ? "justify-content-md-end"
                    : "justify-content-md-start"
                )}
              >
                <div className="m-xl-4">
                  <div
                    className="border-info m-3 m-sm-4 text-center _w-100"
                    style={{ borderWidth: 15, maxWidth: 460 }}
                  >
                    <div
                      className="my-3 my-sm-4 text-white"
                      style={{ fontFamily: "Roboto" }}
                    >
                      <div className="h5">{t("level")}</div>
                      <hr className="mx-auto my-2" style={{ width: 90 }} />
                      <div className="h1">{index + 1}</div>
                    </div>
                    <div
                      className="bg-primary text-info my-3 my-sm-4 py-2"
                      style={{ margin: `0px ${breakpoint === 'xs' ? -28 : -50}px` }}
                    >
                      <h3
                        className="text-uppercase text-bold"
                        style={{ fontSize: 58, fontFamily: "Dosis" }}
                      >
                        {level.title}
                      </h3>
                    </div>
                    <h4
                      className="text-warning fst-italic mx-3 my-0"
                      style={{ fontFamily: "Roboto" }}
                    >
                      {level.subtitle}
                    </h4>
                    <hr
                      className="mx-auto my-3 my-sm-4 text-white"
                      style={{ width: 90 }}
                    />
                    <a
                      href={level.page}
                      className="btn btn-lg btn-primary px-3 px-sm-4 mb-4"
                      style={{ borderColor: "#16365d" }}
                    >
                      {t("readMore")}
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={classNames("col-6 d-none d-md-block", {
                  "order-first": index % 2 == 1,
                })}
                style={{
                  backgroundImage: "url(" + level.image?.url + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          ))}
      </div>
      <div id="gallery">
        <SectionHeadingB title={acf?.gallery?.title} />
        <div className="container-fluid">
          <div className="row g-3">
            {acf?.gallery?.photos &&
              acf?.gallery?.photos.map(({ photo }) => (
                <div className="col col-12 col-sm-6 col-lg-4">
                  <img
                    className="img-fluid zoom"
                    src={photo.url}
                    onClick={() => {
                      zoom(photo.url);
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div id="admissions">
        <DoubleTitleSectionHeading
          title={acf?.admissions?.title}
          subtitle={acf?.admissions?.subtitle}
          className="my-4"
        />
        <div className="container">
          <div className="row">
            {acf?.admissions?.blocks &&
              acf?.admissions?.blocks?.map(
                ({ icon, label }) =>
                  icon && (
                    <div className="col col-12 col-sm-6 col-md-3 text-center">
                      <FontAwesomeIcon
                        icon={icon as IconProp}
                        className={classNames("text-primary h1 mt-4 mb-3 mt-sm-5 mb-sm-4")}
                        style={{ fontSize: 100 }}
                      />
                      <p className="h4 text-uppercase text-primary">{label}</p>
                    </div>
                  )
              )}
          </div>
          <div className="text-center my-4">
            <a
              className="btn btn-lg btn-outline-primary px-4"
              href={acf?.admissions?.cta?.page}
            >
              {acf?.admissions?.cta?.label}
            </a>
          </div>
        </div>
      </div>
      <div id="students-life">
        <DoubleTitleSectionHeading
          title={acf?.students_life?.title}
          subtitle={acf?.students_life?.subtitle}
          className="my-4"
        />
        <div className="container">
          {acf?.students_life?.activities &&
            acf?.students_life?.activities?.map(
              ({ description, image, page, title }) => (
                <div className="row mb-4 justify-content-center">
                  <div className="col-10 col-md-5 order-md-2">
                    <div className="d-flex flex-column h-100 justify-content-center">
                      {image?.url && (
                        <img
                          className="img-fluid mb-2 shadow-sm zoom"
                          src={image?.url}
                          onClick={() => {
                            zoom(image.url);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-10 col-md-5 order-md-1">
                    <div className="text-center text-md-start d-flex flex-column h-100 justify-content-center">
                      <h3 className="mb-md-4">{title}</h3>
                      <p className="mb-md-4">{description}</p>
                      <div className="text-center">
                        <a
                          className="btn btn-lg btn-outline-primary px-4"
                          href={page}
                        >
                          {t("readMore")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      <div
        id="leadership-team"
        className="bg-primary text-white text-center pt-5 pb-4"
      >
        <div className="container">
          <h1 className="mb-4">{acf?.leadership_team?.title}</h1>
          <div className="row justify-content-center">
            <div className="col col-12 col-md-8">
              <h6 className="mb-4">{acf?.leadership_team?.subtitle}</h6>
            </div>
          </div>
        </div>
        <div className="container px-5">
          <div className="row gx-5">
            {acf?.leadership_team?.members &&
              acf?.leadership_team?.members?.map(
                ({ name, role, youtube_video_id }) =>
                  youtube_video_id && (
                    <div className="col col-12 col-md-6">
                      <div className="mb-4">
                        <ResponsoveYouTubeVideo
                          id={youtube_video_id}
                          className="mb-2 shadow"
                        />
                        {name} - {role}
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </>
  );
};
