import React from "react";
import { useModalContext } from "./ModalContext";

export const useImageZoom = () => {
  const { showModal } = useModalContext();

  const zoom = React.useCallback(
    (image: string) => {
      showModal({
        size: "extra-large",
        className: "transparent",
        body: (
          <img src={image} className="img-fluid d-block m-auto shadow-lg" />
        ),
      });
    },
    [showModal]
  );
  return { zoom };
};
