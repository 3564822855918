import * as bootstrap from "bootstrap";
import React from "react";
import ReactDOM from "react-dom/client";
import "./fa.ts";
import "./i18n.ts";
import "./style.scss";
console.log(bootstrap);

import "vite/modulepreload-polyfill";
// import { Footer } from "./Footer.tsx";
// import { Header } from "./Header.tsx";
import { Page } from "./Page.tsx";
import { ACF_HomePage, ACF_PageBuilder } from "./acf.ts";
import { HomePage } from "./pages/HomePage.tsx";
import { PageBuilder } from "./pages/PageBuilder.tsx";
import { DefaultPage } from "./pages/DefaultPage.tsx";
import { Header } from "./Header.tsx";
import { Footer } from "./Footer.tsx";
import { Page404 } from "./pages/Page404.tsx";

document.addEventListener("DOMContentLoaded", () => {
  const acf = window.acf;
  const wordpress = window.wordpress;

  console.log({ acf, wordpress, window });

  const headerWrapper = document.getElementById("react-header");
  if (headerWrapper) {
    ReactDOM.createRoot(headerWrapper).render(
      <React.StrictMode>
        <Header />
      </React.StrictMode>
    );
  }

  const footerWrapper = document.getElementById("react-footer");
  if (footerWrapper) {
    ReactDOM.createRoot(footerWrapper).render(
      <React.StrictMode>
        <Footer />
      </React.StrictMode>
    );
  }

  const defaultPageWrapper = document.getElementById("react-default-page");
  if (defaultPageWrapper) {
    ReactDOM.createRoot(defaultPageWrapper).render(
      <React.StrictMode>
        <Page>
          <DefaultPage />
        </Page>
      </React.StrictMode>
    );
  }

  const homePageWrapper = document.getElementById("react-home-page");
  if (homePageWrapper) {
    ReactDOM.createRoot(homePageWrapper).render(
      <React.StrictMode>
        <Page>
          <HomePage acf={acf as ACF_HomePage} />
        </Page>
      </React.StrictMode>
    );
  }

  const pageBuilderWrapper = document.getElementById("react-page-builder");
  if (pageBuilderWrapper) {
    ReactDOM.createRoot(pageBuilderWrapper).render(
      <React.StrictMode>
        <Page>
          <PageBuilder acf={acf as ACF_PageBuilder} />
        </Page>
      </React.StrictMode>
    );
  }

  const page404Wrapper = document.getElementById("react-page-404");
  if (page404Wrapper) {
    ReactDOM.createRoot(page404Wrapper).render(
      <React.StrictMode>
        <Page>
          <Page404 />
        </Page>
      </React.StrictMode>
    );
  }
});
